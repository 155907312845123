export const mapFileNameToId = (filename:any, markdownFiles:any) => {
  for (let i = 0; i < markdownFiles.length; i++) {    
    if (markdownFiles[i].default.indexOf(filename) !== -1) {
      return markdownFiles[i].default.substring(markdownFiles[i].default.lastIndexOf("/"));
    }
  }

  return null;
};

export const importAll = (r:any) => {
  return r.keys().map(r);
};