import React from "react";
import { Link } from "react-router-dom";

// Shared
import * as url from "../../../shared/routes/index.json";

type BlogCardProps = {
  title?: string;
  date?: string;
  minutes?: string;
  blogPost?: any;
  imageLink?: any;
};

const BlogCard = ({
  title,
  date,
  minutes,
  blogPost,
  imageLink,
}: BlogCardProps) => {
  return (
    <div className="BlogCard">
      <Link to={`${url.Blog}${blogPost}`} className="BlogCard__link">
        <div className="BlogCard__image">
          <img src={`/static/media${imageLink}`} alt={title} />
        </div>
        <h2 className="BlogCard__title">{title}</h2>
        <p className="BlogCard__date">
          {date} | {minutes} read
        </p>
      </Link>
    </div>
  );
};

export default BlogCard;
