import React, { useEffect, useState } from "react";

type BlogImageProps = {
  folder: string;
  image: string;
  imageAlt: string;
};

const BlogImage = ({ folder, image, imageAlt }: BlogImageProps) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    import(`../../../shared/data/images/${folder}/${image}.png`).then(
      (imageLink) => {
        setUrl(imageLink.default);
      }
    );
  });

  return (
    <div className="BlogImage">
      <img className="BlogImage__image" src={url} alt={imageAlt} />
    </div>
  );
};

export default BlogImage;
