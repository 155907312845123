import React from "react";
import { Switch, Route } from "react-router-dom";
import * as url from "./shared/routes/index.json";

// COMPONENTS
import Home from "./containers/Home";
import Blog from './containers/Blog';
import BlogPost from "./containers/BlogPost";

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route exact path={url.Root} component={Home} />
        <Route exact path={url.Blog}component={Blog} />
        <Route exact path={url.BlogPost} component={BlogPost} />
      </Switch>
    </div>
  );
};

export default App;
