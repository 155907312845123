import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = (): JSX.Element => {
  const [showDropDownMenu, setDropDownMenu] = useState<boolean>(false);

  const handleDropDown = () => {
    setDropDownMenu(!showDropDownMenu);
  };

  const MobileNavbar = (): JSX.Element => {
    return (
      <div className="MobileNavbar">
        <div className="MobileNavbar__inner">
          <ul className="MobileNavbar__menu">
            <li className="MobileNavbar__menu-link">
              <NavLink to="/blog">Menu</NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <nav className="Navbar">
      <div className="Navbar__container">
        <div className="Navbar__logo">
          <NavLink to="/">adepam</NavLink>
        </div>
        <button
          className={`Navbar__btn Navbar__btn--${
            showDropDownMenu ? "show" : "hide"
          }`}
          onClick={handleDropDown}
        >
          {showDropDownMenu ? "close" : "menu"}
        </button>
        <ul className="Navbar__menu-links">
          <li className="Navbar__menu-link">
            <NavLink to="/blog">Blog</NavLink>
          </li>
        </ul>
      </div>
      {showDropDownMenu && <MobileNavbar />}
      
      
    </nav>

  );
};

export default Navbar;
