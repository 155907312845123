import React from "react";

type BlogHeaderProps = {
  title: string;
  date: string;
  min: string;
};

const BlogHeader = ({ title, date, min }: BlogHeaderProps) => {
  return (
    <div className="BlogHeader">
      <h1 className="BlogHeader__title">{title}</h1>
      <h4 className="BlogHeader__date">
        {date} - {min} read
      </h4>
    </div>
  );
};

export default BlogHeader;
