import React from "react";
import { Link } from "react-router-dom";
import * as url from "../../shared/routes/index.json";

// Layout
import AppLayout from "../../layouts/AppLayout";

// Utils
import { mapFileNameToId, importAll } from "../../shared/utils/blog";
import blog from "../../shared/data/blog.json";

// Components
import BlogCard from "../../components/Blog/BlogCard";

const blogFiles = importAll(
  require.context("../../shared/blogs", false, /\.md$/)
);

const imageFiles = importAll(
  require.context("../../shared/data/images/blog", false, /\.png$/)
);

const Blog = () => {
  return (
    <AppLayout>
      <div className="Blog">
        <div className="Blog__inner">
          <div className="Blog__header">
            <h1 className="Blog__title">Blog</h1>
          </div>
          <div className="Blog__content">
            {blog.blogItems.length > 0 ? (
              blog.blogItems.map((blogPost: any) => (
                <BlogCard
                  key={blogPost.title}
                  imageLink={mapFileNameToId(blogPost.image, imageFiles)}
                  title={blogPost.title}
                  date={blogPost.date}
                  minutes={blogPost.minutes}
                  blogPost={mapFileNameToId(blogPost.blogPost, blogFiles)}
                />
              ))
            ) : (
              <div className="Blog__content--empty">
                <h3>No blog post yet :(</h3>
                <Link to={url.Root}>Back to Home</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Blog;
