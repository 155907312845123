import React, { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";

import AppLayout from "../../layouts/AppLayout/";
import BlogHeader from "../../components/Blog/BlogHeader";
import BlogImage from '../../components/Blog/BlogImage';

const BlogPost = ({ match }: any) => {
  const [post, setPost] = useState<string>("");

  useEffect(() => {
    fetch(`/static/media/${match.params.blogPost}`)
      .then((res) => res.text())
      .then((text) => {
        setPost(text);
      })
      .catch((err) => setPost("Error retrieving blog post" + err));
  }, [match]);

  return (
    <AppLayout>
      <div className="BlogPost">
        <div className="BlogPost__inner">
          <div className="BlogPost__content">
            <Markdown
              options={{
                overrides: {
                  BlogHeader: {
                    component: BlogHeader,
                  },
                  BlogImage: {
                    component: BlogImage,
                  }
                },
              }}
            >
              {post}
            </Markdown>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default BlogPost;
