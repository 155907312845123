import React from "react";

const Footer = () => {
  return (
    <footer className="Footer">
      <p className="Footer__info">adepam &copy; {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
