import React from "react";
import { Formik } from "formik";
import app from "../../shared/Firebase/fbConfig";

// COMPONENTS
import AppLayout from "../../layouts/AppLayout";
import GooglePlayStore from "../../components/Icons/GooglePlayStore";
import AppleStore from "../../components/Icons/AppleStore";
import Footer from "../../components/Footer";

import AdepamSignin from "../../assets/images/adepam-signin.png";
import AdepamProducts from "../../assets/images/adepam-1.png";
import AdepamBuy from "../../assets/images/adepam-buy.png";
import ScreenShot1 from "../../assets/images/screenshot-1.png";
import ScreenShot2 from "../../assets/images/screenshot-2.png";
import ScreenShot3 from "../../assets/images/screenshot-3.png";

const Home = (): JSX.Element => {
  const [testerMsg, setTesterMsg] = React.useState("");
  const initialValues: any = { email: "" };
  return (
    <div className="Home">
      <AppLayout>
        <header className="Home__header">
          <div className="Home__header-inner">
            <div className="Home__header-content">
              <h1 className="heading-1 mb-sm">
                Connecting fashion designers and makers to consumers and
                merchants
              </h1>
              <p className="mb-xl">
                A platform that connects independent fashion designers and
                makers to merchants and consumers in Africa.{" "}
              </p>
            </div>
            <div className="Home__header-media">
              <div className="Home__header-img">
                <img src={AdepamProducts} alt="" width="500" height="500" />
              </div>
            </div>
            <div className="Home__header-platforms">
              <Formik
                validate={(values) => {
                  const errors: any = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  return errors;
                }}
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                  app
                    .firestore()
                    .collection("testers")
                    .add({
                      email: values.email,
                    })
                    .then(() => {
                      setTesterMsg(
                        "Thanks for your interest! You're now a tester."
                      );
                      resetForm();
                    })
                    .catch((err) => {
                      alert(err);
                    });
                }}
              >
                {({ values, errors, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <p className="form-success">
                      {testerMsg ? testerMsg : null}
                    </p>
                    <p className="form-error">{errors.email && errors.email}</p>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      placeholder="enter your email"
                      className="email-input"
                    />
                    <button type="submit" className="become-a-tester">
                      Sign up for early preview
                    </button>
                  </form>
                )}
              </Formik>

              <p className="caption mb-md">Coming soon:</p>
              <div className="Home__header-platform-icons">
                <span>
                  <a href="#google-play-store" aria-label="Google Play Store">
                    <GooglePlayStore />
                  </a>
                </span>
                <span>
                  <a href="#apple-store" aria-label="Apple Store">
                    <AppleStore />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </header>
        <section className="Hiw">
          <div className="Hiw__inner">
            <h2 className="Hiw__title">How adepam works</h2>
            <div className="Hiw__merchant">
              <div className="Hiw__merchant-wrapper">
                <div className="Hiw__steps">
                  <h3 className="Hiw__subtitle">Become a merchant</h3>
                  <div className="Hiw__step">
                    <span className="Hiw__step-indicator">1</span>
                    <p className="Hiw__step-instruction">
                      Sign in to the app using your email or facebook username
                    </p>
                  </div>
                  <div className="Hiw__step">
                    <span className="Hiw__step-indicator">2</span>
                    <p className="Hiw__step-instruction">
                      Create a unique merchant business account for free.
                    </p>
                  </div>
                  <div className="Hiw__step">
                    <span className="Hiw__step-indicator">3</span>
                    <p className="Hiw__step-instruction">
                      Set up your portfolio and sell.
                    </p>
                  </div>
                </div>
                <div className="Hiw__video">
                  <img src={AdepamSignin} alt="" width="500" height="500" />
                </div>
              </div>

              <div className="Hiw__buyer-wrapper">
                <div className="Hiw__video">
                  <img src={AdepamBuy} alt="" className="Hiw__buy-screnshots" />
                </div>
                <div className="Hiw__steps">
                  <h3 className="Hiw__subtitle">Become a Buyer</h3>
                  <div className="Hiw__step">
                    <span className="Hiw__step-indicator">1</span>
                    <p className="Hiw__step-instruction">
                      Sign in to the app using your email or facebook username.
                    </p>
                  </div>
                  <div className="Hiw__step">
                    <span className="Hiw__step-indicator">2</span>
                    <p className="Hiw__step-instruction">
                      Select a product and make payment.
                    </p>
                  </div>
                  <div className="Hiw__step">
                    <span className="Hiw__step-indicator">3</span>
                    <p className="Hiw__step-instruction">
                      Product gets delivered to you at a fee.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Screenshots">
          <div className="Screenshots__inner">
            <h2 className="Screenshots__title">In-App</h2>
            <div className="Screenshots__images">
              <div className="Screenshots__image">
                <img src={ScreenShot1} alt="" />
              </div>
              <div className="Screenshots__image">
                <img src={ScreenShot2} alt="" />
              </div>
              <div className="Screenshots__image">
                <img src={ScreenShot3} alt="" />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </AppLayout>
    </div>
  );
};

export default Home;
